<template>
  <div class="Dynamic">
    <div class="head_banner">
      <div class="banner1">
        <h2>为客户提供全球跨境投资及争议解决</h2>
        <h2>一站式法律服务</h2>
        <div class="banner_tel" @click="callPhone('400-662-2729')">
          <img src="@/assets/bannertel.png" alt="" />
          400-662-2729
        </div>
      </div>
    </div>
    <div class="contain" id="htmlContent">
      <div class="title"><i></i>盈科动态<span>YINGKE DYNAMIC</span></div>
      <div class="list">
        <ul>
          <li v-for="(x, i) in topDynamics" :key="i">
            <div class="top">
              <div class="model">
                <img src="@/assets/heng.png" alt="" />
                <img
                  :src="require('../../assets/0' + (i + 1) + '.png')"
                  alt=""
                />
                <img src="@/assets/heng.png" alt="" />
              </div>
            </div>
            <div class="bottom">
              <div class="biaoti">{{ x.title }}</div>
              <div class="msg">
                {{ x.intro }}
              </div>
              <div class="time">
                <span>{{ x.publishYear + " " + x.publishMonthDay }}</span
                ><span @click="goDetails(x.id)">了解全部>></span>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="page" v-show="isShowTable">
        <div class="page_cont">
          <div
            class="page_msg"
            v-for="(x, i) in dynamics"
            :key="i"
            @click="goDetails(x.id)"
          >
            <div class="left">
              <h4>{{ x.title }}</h4>
              <div class="text">{{ x.intro }}</div>
            </div>
            <div class="right">
              <div class="year">{{ x.publishYear }}</div>
              <div class="monthDay">{{ x.publishMonthDay }}</div>
            </div>
          </div>
        </div>
        <div class="page_num">
          <el-pagination
            @current-change="topage"
            background
            layout="prev, pager, next"
            :total="pageCount"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      topDynamics: [], //顶部动态文章
      dynamics: [], //律所动态文章列表
      isShowTable: false,
      DesceOrder: true, //是否降序
      OrderIconVal: 0, //选中的图标值
      CurrentPage: 1, //当前页
      pageCount: -1, //总页数
      title: "",
      img1: require("../../assets/01.png"),
      img2: require("../../assets/02.png"),
      img3: require("../../assets/03.png"),
    };
  },
  created() {
    this.$store.state.navChange = 5;
    this.CurrentPage = 1;
    this.getArticles();
  },
  methods: {
    //跳转文章详情
    goDetails(id) {
      let that = this;
      this.$store.state.DesceOrder = that.DesceOrder;
      this.$router.push({
        name: "Dynamic-detail",
        params: {
          id: id,
          order: that.OrderIconVal,
          articleType: "dynamic",
        },
      });
    },
    //获得文章列表
    getArticles() {
      let _t = Math.random();
      this.axios({
        type: "get",
        url: this.Url.apiUrl + "Articles/GetArticles",
        params: {
          _: _t,
          type: 1,
          title: this.title,
          CurrentPage: this.CurrentPage,
          num: 10,
          isDesc: this.DesceOrder,
        },
      }).then((res) => {
        let datas = res.data.data;
        this.topDynamics = datas.slice(0, 3); //前三篇文章
        datas.splice(0, 3);
        this.dynamics = datas; //其余文章
        this.isShowTable = this.dynamics.length > 0; //判断其余文章是否有内容
        this.pageCount = Math.ceil((res.data.count / 10) * 10);
      });
    },
    //点击分页事件
    topage(val) {
      this.CurrentPage = val;
      this.getArticles();
    },
    //搜索文章
    GetkeyWordArtice() {
      this.CurrentPage = 1;
      this.getArticles();
    },
  },
  mounted() {
    setTimeout(function () {
      let htmlContent = document.getElementById("htmlContent");
      htmlContent.scrollIntoView({ behavior: "smooth" });
    }, 100);
  },
};
</script>

<style lang="sass" scoped>
@import "@/styles/dynamic.scss"
</style>